#layout-footer {
	background-color: $footer-background-color
}

#layout-footer .layout-footer_branding {
	-webkit-box-ordinal-group: 1;
	order: -1;
	flex: 1 1 auto;
	align-self: auto
}

#layout-footer {
	.navbar-toggler {
		display: none;
	}
}

#layout-footer .footer-menu {
	margin-top: -0.9rem;

	@include media-breakpoint-down(md) {
		margin-top: 0;
	}
}

#layout-footer .layout-footer_contacts {
	order: 2;
	flex: 1 1 auto;
	align-self: auto
}

#layout-footer .layout-footer_contacts p {
	color: $white;
	span {
	  color: $white;
	}
}

#layout-footer .navbar-nav {
	display: flex
}

#layout-footer .navbar-nav li {
	display: flex;
	text-align: left;
	flex-basis: 50%;
}

#layout-footer .navbar-nav li a {
	color: $white;
	transition: $transition-base;
	span {
	  color: $white;
	  &:hover {
		color: $primary;
	  }
	}
}

#layout-footer .menu-phone-contacts {
	display: none !important;
}

#layout-footer .footer-navigation__copyright-logotype {
	height: 40px;
	background-image: url(../Content/img/logo-white.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	margin-bottom: 24px;
	width: 150px;

	@include media-breakpoint-down(sm) {
		width: 130px;
		height: 34px;

	}
}

#layout-footer .container__footer-row .container__footer-navigation .footer-navigation__copyright .footer-navigation__copyright-copyright-text {
	line-height: 1.5rem;
	font-family: $font-family-sans-serif;
	font-weight: 300;
	font-size: .75rem;
	color: $white;
	height: 34px;
}

#layout-footer .footer-navigation__section-phone {
	font-size: 1.25rem;
	color: $red-400 !important;
}

@media (max-width:992px) {
	#layout-footer .navbar-toggler {
		display: none
	}

	#layout-footer .collapse {
		display: block
	}
}

@media (max-width:768px) {
	#layout-footer .footer-navigation__section-phone {
		font-size: 1rem;

	}
	#layout-footer {
		font-size: 0.9rem;
	}

	#layout-footer .navbar-nav li {
		display: flex;
		text-align: left;
		flex-basis: 50%
	}

	#layout-footer .layout-footer_contacts {

		order: 1
	}

	#layout-footer .footer-menu-lt.widget-footer.widget-menu-widget.widget,
	#layout-footer .footer-menu.widget-footer-menu.widget-footer.widget-menu-widget.widget {

		order: 2
	}

	#layout-footer .footer-menu-lt.widget-footer.widget-menu-widget.widget,
	#layout-footer .footer-menu.widget-footer-menu.widget-footer.widget-menu-widget.widget {

		min-width: 100%
	}

	#layout-footer .navbar-collapse {
		border-top: 1px solid;
		min-width: 100%;
		border-color: $white;
		display: block !important;

	}

	#layout-footer .navbar-nav {
		margin-top: 1rem;
		flex-direction: row
	}

}

@media (max-width: 1199px) {
	#layout-footer .collapse:not(.show) {
		display: block !important;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	#layout-footer .navbar-nav li a { 
		padding: 0 0 5px 0;
	}
}


#layout-footer .nav-item .raw p {
	margin: 0
}
