.landing-heading {
  min-height: 650px;

  @include media-breakpoint-down(xl) {
    min-height: 1000px;
  }

  @include media-breakpoint-down(lg) {
    min-height: 950px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 600px;
  }
}

.hello-animation {
  animation: emersion-top 1s ease-in-out normal;
}

.bounce-animation {
  animation: bounce 1s ease-in-out normal;
}

.section-wrapper.use-service {
}

.section-map {
  .country-list {
    padding: 0;
    cursor: default;

    .country-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      list-style: none;

      &:hover {
        color: $primary;
      }

      .country-name {
        margin-left: 5px;

        @include media-breakpoint-down(lg) {
          font-size: 0.9rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 0.7rem;
        }
      }

    }
  }

  .map {
    max-width: 100%;

  }

  #map-item-ua {
    animation: color-fill 32s 2s ease-out infinite;
  }
  #map-item-ng {
    animation: color-fill 32s 4s ease-out infinite;
  }
  #map-item-in {
    animation: color-fill 32s 6s ease-out infinite;
  }
  #map-item-fi {
    animation: color-fill 32s 8s ease-out infinite;
  }
  #map-item-gb path {
    animation: color-fill 32s 10s ease-out infinite;
  }
  #map-item-de {
    animation: color-fill 32s 12s ease-out infinite;
  }
  #map-item-ch {
    animation: color-fill 32s 14s ease-out infinite;
  }
  #map-item-uz {
    animation: color-fill 32s 16s ease-out infinite;
  }
  #map-item-fr {
    animation: color-fill 32s 18s ease-out infinite;
  }
  #map-item-bg {
    animation: color-fill 32s 20s ease-out infinite;
  }
  #map-item-es {
    animation: color-fill 32s 22s ease-out infinite;
  }
  #map-item-gh {
    animation: color-fill 32s 24s ease-out infinite;
  }
  #map-item-kz {
    animation: color-fill 32s 26s ease-out infinite;
  }
  #map-item-pl {
    animation: color-fill 32s 28s ease-out infinite;
  }
  #map-item-pk {
    animation: color-fill 32s 30s ease-out infinite;
  }

  .fill {
    fill: $primary;
  }

  .map-item-transition {
    animation: 0 !important;
    transition: fill 0.3s ease;
  }

  .transfer-info {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .money-transfer-img {
      margin-right: 30px;
    }
  }
}

.benefits {
  @include media-breakpoint-down(xl) {
    margin-top: 30px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &-text {
    margin-top: 20px;
    max-width: 235px;

    @include media-breakpoint-down(md) {
      font-size: 0.9rem;
    }
  }
}

.download-app {
  color: $black;
  padding: 20px 0;

  @include media-breakpoint-down(lg) {
    padding: 0;
  }

  &-wrapper {
    padding: 80px 40px;

    background: $blue-200;
    border-radius: $border-radius-lg;

    @include media-breakpoint-down(lg) {
      padding: 45px 40px;
    }

    @include media-breakpoint-down(sm) {
      padding: 35px 20px;
    }

    .download-app-text,
    .payment-text {
      font-size: 1.25rem;
      font-weight: $font-weight-medium;

      @include media-breakpoint-down(xl) {
        font-size: 1rem;
      }

      @include media-breakpoint-down(lg) {
        text-align: center;
      }
    }

    .download-app-header {
      text-align: left;

      @include media-breakpoint-down(lg) {
        text-align: center;
      }
    }

    .download-app-img {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .download-app-item {
        margin-right: 14px;
      }

      @include media-breakpoint-down(lg) {
        justify-content: center;
      }
    }

    .download-app-icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      .download-app-item {
        margin-right: 11px;

        &:last-child {
          margin-right: 11px;
        }
      }

      @include media-breakpoint-down(lg) {
        justify-content: center;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;

        .download-app-item {
          margin: 0 0 10px 0;

          &:last-child {
            margin: 0;
          }
        }

        .item-img {
          height: 70px;
        }
      }

      .store,
      .gallery,
      .play {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .show {
      display: block !important;
    }
  }

  .app-wrapper {
    position: relative;

    .app-img {
      position: absolute;
      top: 50%;
      transform: translateY(-55%);

      @include media-breakpoint-down(xl) {
        transform: translateY(-50%);
      }

      @include media-breakpoint-down(lg) {
        position: static;
        transform: none;
      }

    }
  }

}

.security {
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .security-img {
      margin-bottom: 1.5rem;
    }
  }
}

svg {
  overflow: visible;

  * {
    overflow: visible;
  }
}



