.login-btn {
  position: relative;
  background: transparent;
  overflow: hidden;
  border: 0;

  &:after {
    position: absolute;
    width: 650px;
    height: 250px;
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(90deg, $primary 0%, rgba(156,42,135,1) 50%, $secondary 100%);
    animation: gradient 10s infinite reverse;
  }

  @keyframes gradient {
    from {
      transform: translateX(0%);
    }
  50% {
    transform: translateX(-555px);
  }
    to {
      transform: translateX(0%);
    }
  }
}


.dropdown-toggle::after {
  border-bottom-color: $white;
  border-top-color: $white;
}
.dropdown-item {
  &.current {
      display: none;
  }
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-menu {
  min-width: 5rem;
}

@media (max-height: 700px) {
  .dropdown-menu {
    &.show {
      display: flex;
      flex-wrap: wrap;
      max-height: 50vh;
      overflow-y: auto;
    }
  }
}
