.fit-content {
  min-width: fit-content
}
//Хелпер для красного цвета
.text-light-red {
  color: $red-400;
}

//Переопределение стилей флагов для карты
.country-flag-sm {
  width: 24px;
  height: 24px;
  background-size: cover;
  border-radius: 50%;
  box-shadow: $box-shadow;
}

//Переопределение стилей флагов для навигации
.country-flag {
  width: 30px;
  height: 30px;
  border-radius: 1rem;
  object-fit: none;
  background-size: cover;
}

//Хелпер для жирности шрифтов
.fw-semibold {
  font-weight: 600;
}

//Хелпер для размера шрифта 20px
.fs-7 {
  font-size: 20px;
}