.calculator-main {
    @include media-breakpoint-down(sm) {
        padding: 0 16px;
    }
}

.calculator {
    margin-bottom: 100px;

    @include media-breakpoint-down(xl) {
        margin-bottom: 0;
    }

    border-radius: $input-border-radius;

    label {
        font-size: $font-size-base;
        padding-left: 14px;
    }

    &-list {
        padding-left: 14px;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .ng-select-container .ng-value-label {
        color: white;
    }

    &-wrapper {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }

    &-table {
        font-size: $font-size-base;
        line-height: 1.1;
    }

    .btn-group {
        display: flex;
        gap: 8px;
        ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;

        @include media-breakpoint-down(sm) {
            padding-left: 18px;
        }
    }

    .btn-group::-webkit-scrollbar {
        display: none;
    }

    .btn-group .btn-primary {
        background: $white;
        color: $primary;
        border: 1px solid $primary;
        padding: 19px 12px;
        font-size: 20px;
        line-height: 1;

        &.active {
            background: $primary;
            color: $white;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
}

.calculator-item {
    .ng-select .ng-arrow-wrapper .ng-arrow {
        background: url('../content/img/bg/arrow-gray.svg') no-repeat;
        width: 15px;
        height: 6px;
        border: none;
        transition: $transition-base;
    }

    .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
        transform: rotate(180deg);
    }
}

.calculator-item.calculator-valuta {
    height: 100%;

    .ng-select {
        height: 100%;
        font-size: $font-size-lg;
    }

    .ng-value-label {
        color: $black !important;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container {
        padding-left: 8px;

        @include media-breakpoint-down(xl) {
            padding-left: 6px;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 0;
        }

        @include media-breakpoint-down(md) {
            padding-left: 7px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 10px;
        }
    }

    .ng-select .ng-dropdown-panel {
        z-index: 10 !important;
        border: none;
    }

    .ng-select.ng-select-opened .ng-select-container {
        z-index: 10 !important;
    }

    .form-control-lg {
        padding: 0;
    }

    .ng-select.ng-select-single .ng-select-container {
        height: 100%;
        border-radius: 0 $input-border-radius $input-border-radius 0;
        cursor: pointer;
        border: 1px solid $gray-300;
        border-left: none;
        margin-left: -0.5px;
    }

    .ng-select .ng-arrow-wrapper .ng-arrow {
        background: url('../content/img/bg/arrow-gray.svg') no-repeat;
        width: 15px;
        height: 6px;
        border: none;
        transition: $transition-base;
    }

    .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
        transform: rotate(180deg);
    }
}

.calculator-row {
    input.form-control {
        border: 1px solid $gray-300;
        border-right: none;
        box-shadow: none;
        border-radius: $input-border-radius 0 0 $input-border-radius;
        font-size: $font-size-lg;
        color: #3F3D3D;
        padding: 12px;
        height: 60px;
    }
}

.calculator-button {
    font-size: $font-size-lg;
    padding: .686rem 3.5rem;
    border-radius: $btn-border-radius;

    &:after {
        position: absolute;
        right: .5rem;
        margin-right: 0;
        font-size: 18px;
    }
}

.calculator-item-down {
    position: absolute;
    width: auto;
    right: 15px;
    left: 15px;
    bottom: 0;

    .calculator-item-link {
        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
    }
}

.calculator-footer {
    display: flex;
    width: 100%;
    height: 120px;
    max-width: 1140px;
    margin: auto;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
        max-width: 900px;
    }

    @include media-breakpoint-down(md) {
        max-width: 660px;
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        height: auto;
        position: relative;
    }
}

.calculator-block {
    @include media-breakpoint-down(md) {
        padding: 0;
    }

    @include media-breakpoint-down(sm) {
        overflow: hidden;
        background-color: $white;
    }

    .calculator-text {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    .calculator-img {
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(10%);
            content: '';
            width: 704px;
            height: 744px;
            background: url('../content/img/images/main-img.svg') center center no-repeat;
        }

        &-sm {
            max-width: 430px;
            margin: 0px auto;
            display: flex;
        }
    }

    .calculator-item {
        .ng-select .ng-dropdown-panel {
            z-index: 10 !important;
            margin-top: 8px;
            border-radius: $input-border-radius;
        }

        .flag-icon {
            width: 24px;
            height: 24px;
            border-radius: 1rem;
            object-fit: none;
            background-size: cover;
            box-shadow: $box-shadow;
        }

        .ng-select .ng-dropdown-panel .scroll-host {
            border-radius: $input-border-radius;
        }

        .ng-select.ng-select-opened .ng-select-container {
            z-index: 10 !important;
        }

        .ng-select.ng-select-single .ng-select-container {
            height: 60px;

            .ng-value-container {
                align-self: center;
                font-size: $font-size-lg;
                padding-left: 12px;

                .ng-input>input {
                    cursor: pointer;
                    line-height: 45px;
                    margin-left: 22px;
                }
            }
        }
    }

    .form-control:focus {
        outline: none !important;
        outline-color: $white;
        box-shadow: none !important;
    }

    textarea.form-control {
        height: 100% !important;
    }

    .custom-calculator-app-preloader {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // Loading indicator
    .loading-indicator {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    .loading-indicator-map {
        display: block;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-dropdown;
        background-color: rgba(84, 109, 169, 0.3);
    }

    .loading-indicator-map-icon {
        position: absolute;
        z-index: 999;
        height: 2em;
        width: 2em;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .mat-progress-spinner,
    .mat-spinner {
        circle {
            stroke: #4abe9d;
        }
    }

    .ng-select.ng-select-opened>.ng-select-container {
        background: $white;
    }

    .ng-select.ng-select-opened>.ng-select-container:hover {
        box-shadow: none;
    }

    .ng-select.ng-select-opened.ng-select-top>.ng-select-container {
        border-radius: $input-border-radius;
    }

    .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        border-color: $gray-300;
        outline: 0;
        box-shadow: none;
    }

    .ng-select.ng-select-disabled>.ng-select-container {
        background-color: #f9f9f9;
    }

    .ng-select .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-select .ng-select-container {
        color: #333;
        background-color: $white;
        border-radius: 14px;
        border: 1px solid $gray-300;
        min-height: 36px;
        align-items: center;

        &:focus {
            border-color: #a8e0d0;
            outline: 0;
            box-shadow: none;
        }
    }

    .ng-select .ng-select-container:hover {
        box-shadow: none;
    }

    .ng-select .ng-select-container .ng-value-container {
        align-items: center;
        padding-left: 10px;
    }

    [dir="rtl"] .ng-select .ng-select-container .ng-value-container {
        padding-right: 10px;
        padding-left: 0;
    }

    .ng-select .ng-select-container .ng-value-container .ng-placeholder,
    .ng-select .ng-select-container .ng-value-container .ng-value {
        color: #3F3D3D;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .ng-select.ng-select-single .ng-select-container {
        height: 36px;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        top: 5px;
        left: 0;
        padding-left: 1rem;
        padding-right: 50px;
    }

    [dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-right: 10px;
        padding-left: 50px;
    }

    .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
        background-color: #f9f9f9;
        border: 1px solid #e6e6e6;
    }

    .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
        padding: 0 5px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
        padding-right: 7px;
        padding-left: 0;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        font-size: .9em;
        margin-bottom: 5px;
        background-color: #ebf5ff;
        border-radius: 2px;
        margin-right: 5px;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        margin-right: 0;
        margin-left: 5px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
        background-color: #f9f9f9;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
        padding-left: 5px;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
        padding-left: 0;
        padding-right: 5px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
        display: inline-block;
        padding: 1px 5px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
        display: inline-block;
        padding: 1px 5px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
        background-color: #d1e8ff;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
        border-right: 1px solid #b8dbff;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
        border-left: 1px solid #b8dbff;
        border-right: none;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
        border-left: 1px solid #b8dbff;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
        border-left: 0;
        border-right: 1px solid #b8dbff;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
        padding: 0 0 3px 3px;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
        padding: 0 3px 3px 0;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
    }

    [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        padding-right: 3px;
        padding-left: 0;
    }

    .ng-select .ng-clear-wrapper {
        color: #999;
    }

    .ng-select .ng-clear-wrapper:hover .ng-clear {
        color: #D0021B;
    }

    .ng-select .ng-spinner-zone {
        padding: 5px 5px 0 0;
    }

    [dir="rtl"] .ng-select .ng-spinner-zone {
        padding: 5px 0 0 5px;
    }

    .ng-select .ng-arrow-wrapper {
        width: 25px;
        padding-right: 5px;
        display: flex;
        align-items: center;
    }

    [dir="rtl"] .ng-select .ng-arrow-wrapper {
        padding-left: 5px;
        padding-right: 0;
    }

    .ng-select .ng-arrow-wrapper:hover .ng-arrow {
        border-top-color: #666;
    }

    .ng-select .ng-arrow-wrapper .ng-arrow {
        border-color: #999 transparent transparent;
    }

    .ng-dropdown-panel {
        background-color: $white;
        box-shadow: none;
        left: 0;
    }

    .ng-dropdown-panel.ng-select-bottom {
        top: 100%;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-top: -1px;
    }

    .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
        border-radius: 0;
    }

    .ng-dropdown-panel.ng-select-top {
        bottom: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-color: transparent;
        margin-bottom: -1px;
        border: none;
    }

    .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
        border-radius: 0;
    }

    .ng-dropdown-panel .ng-dropdown-header {
        border-bottom: 1px solid $gray-200;
        padding: 5px 7px;
    }

    .ng-dropdown-panel .ng-dropdown-footer {
        border-top: 1px solid $gray-200;
        padding: 5px 7px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items {
        border: 1px solid $gray-200;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
        user-select: none;
        padding: 8px 10px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
        cursor: default;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
        background-color: #f5faff;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
        background-color: #ebf5ff;
        font-weight: 600;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        padding: 8px 10px;
        border-radius: $input-border-radius;
        display: flex !important;
        align-items: center;
        gap: 8px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
        color: #333;
        background-color: #ebf5ff;
        border-radius: 0;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
        font-weight: 600;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: #f5faff;
        border-radius: 0;
        color: #333;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
        color: $gray-200;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
        padding-left: 22px;
    }

    [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
        padding-right: 22px;
        padding-left: 0;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
        font-size: 80%;
        font-weight: $font-weight-normal;
        padding-right: 5px;
    }

    [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
        padding-left: 5px;
        padding-right: 0;
    }

    [dir="rtl"] .ng-dropdown-panel {
        direction: rtl;
        text-align: right;
    }

    @media (max-width: 575.98px) {
        .form-group-sm {
            padding: 0 30px;
        }

        .calculator .btn-group {
            padding-left: 15px;
        }

        .landing-heading-copyright {
            padding: 0 15px;
        }
    }

    .form-group-btn {
        label {
            @include media-breakpoint-down(sm) {
                padding-left: 30px;
            }
        }

        .btn-primary::after {
            display: none;
        }
    }

    .loading-indicator-map {
        background-color: $white;
    }

    .loading-indicator {
        position: relative;
    }

    .calculator-loading {
        position: absolute;
        left: 0;
        top: 6px;
        z-index: 99;
        width: 100%;
        padding: 0 15px;

        @media (max-width: 575.98px) {
            left: 15px;
            right: 15px;
            width: auto;
        }
    }

    .calculator-loading .form-group {
        margin-bottom: 1.4rem;
    }

    .input-block {
        background-color: $gray-500;
        width: 100%;
        height: 60px;
        border-radius: $border-radius;
    }

    .input-block-tr {
        width: 100%;
        height: 60px;
        background: lighten($secondary, 10%);
        border-radius: $border-radius;
    }

    .input-block-sm {
        width: 25%;
    }

    .label {
        background-color: $gray-500;
        width: 40%;
        height: 16px;
        margin-bottom: 8px;
        border-radius: $border-radius;
    }

    @media (max-width: 992px) {
        .input-block-sm {
            width: 40%;
        }

        .label {
            width: 50%;
        }
    }

    @media (max-width: 576px) {
        .label-sm {
            margin-top: 0.5rem;
        }
    }

    .input-block,
    .label {
        animation: loadingAnimation 2s linear infinite;
        background: linear-gradient(to right,
                rgba(255, 255, 255, 0) 46%,
                rgba(255, 255, 255, 0.35) 50%,
                rgba(255, 255, 255, 0) 54%) 50% 50% rgba(163, 163, 163, 0.2);
        background-size: 1000px auto;
    }

    @keyframes loadingAnimation {
        0% {
            background-position: -1000px 0;
        }

        100% {
            background-position: 1000px 0;
        }
    }

    .btn-group-calculator {
        height: 60px;
    }
}