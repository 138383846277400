#topButton {
    position: fixed !important;
    outline: 0 !important;
    overflow: hidden !important;
    cursor: pointer !important;
    width: auto !important;
    height: auto !important;
    background: $up-button-background-color !important;
    right: 15px !important;
    bottom: 6rem !important;
    border: none !important;
    font-size: 20px !important;
    padding: 0.4rem 0.8rem!important;
    opacity: $default-opacity !important;
    transition: $transition-base !important;
    color: $black !important
}

#topButton:hover {
    opacity: 1 !important;
    background-color: $primary !important;
    color: $white !important
}