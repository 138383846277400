//Добавление стилей аккордеона
.accordion-button {
    padding-left: 35px;
    font-weight: $font-weight-semibold;
}

// Переопределение некоторых стилей Аккордеона
.accordion-button::after {
    position: absolute;
    left: 0;
}