#language-selector .language-selector__toggler:hover {
	text-decoration: none
}

#language-selector .language-selector__toggler {
  @include media-breakpoint-down(sm) {
    padding: 0.2rem;
  }
}

#language-selector .language-selector__dropdown a {
	opacity: .6
}

#language-selector .language-selector__dropdown a:hover {
	opacity: 1
}

@media all and (max-width:992px) {
    article.culture-picker.widget-culturePicker.widget-navigation.widget-culture-picker-widget.widget {
        position: absolute;
        left: 16rem;
        padding: .5rem 0
    }
}
