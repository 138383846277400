@keyframes pulse {
    0% {
        transform: translate(0,0);
    }
    50% {
        transform: translate(5px,0);
    }
    100% {
        transform: translate(0,0);
    }
}

@keyframes color-fill {
    0% {
        fill: $gray-200;
    }
    6% {
        fill: $primary;
    }
    13% {
        fill: $gray-200;
    }
    100% {
        fill: $gray-200;
    }

}