// from bundle css
#layout-navigation {
	font-family: $font-family-sans-serif;
	display: flex; 
	align-items: center;
	z-index: $zindex-sticky;
}

#layout-navigation .widget-culture-picker-widget {
	order: 3
}

#layout-navigation .navbar-brand .logo-copyright {
	font-size: .8rem;
	z-index: 1;
	white-space: normal;
	line-height: inherit;
	@include media-breakpoint-down(lg) {
		white-space: normal;
		line-height: inherit;
	}
}

#layout-navigation .colored-text {
	color: $primary
}

#layout-navigation .bg-light.zone.zone-navigation { 
	transition: .3s ease-in-out;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .1), 0 1px 0 rgba(0, 0, 0, .1);
	@include media-breakpoint-down(md) {
		padding: .5rem 1rem .5rem 0.5rem;
	}
	@include media-breakpoint-down(sm) {
		padding: .5rem 1rem;
	}
}
.widget-before-content {
	@include media-breakpoint-down(sm) {
		margin: 0 -15px;
	}
}
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
	color: $primary;
	span {
		color: $primary;
	}
}
@media all and (min-width:1199px) {
	#layout-navigation {
		background-color: transparent!important; 
		transition: background 1s ease-in-out
	}
	#layout-navigation nav.bg-dark {
		background-color: transparent!important;
		color: $white; 
		transition: background .5s ease-in-out
	}
}

@media all and (min-width:992px) {
	#layout-navigation:not(.sticky) .zone-navigation a {
		opacity: 1!important
	}
}
@media all and (min-width:1200px) and(max-width:1300px) { 
	#layout-navigation .logo-copyright {
		display: block !important;
		max-width: initial !important;
		width: 100%;
		margin-top: -10px;
	} 
}
@media all and (max-width:1199px) {
	#layout-navigation .navbar.zone-navigation {
		display: flex; 
		align-items: center;
		@include media-breakpoint-down(md) {
			padding: .5rem 1rem .5rem 0.5rem;
		}
		@include media-breakpoint-down(sm) {
			padding: .5rem 1rem;
		}
	}
	#layout-navigation .logo-copyright {
		max-width: 190px;
	}
	#layout-navigation .navbar-toggler {
		border: none;
		background: 0 0!important;
		padding: .7rem 0;

	}
	#language-selector.dropdown {
		margin-right: 50px;
		.dropdown-toggle {
			padding-right: 0;
		}
	}
	#layout-navigation .navbar {
		position: fixed; 
		right: 0;
		left: 0;
		width: 100%;
	}
	#layout-navigation .link-login {
		margin: .5rem 0;
		position: absolute;
		left: 10rem
	}
	#layout-navigation li.nav-item {
		padding: 2rem 0 0 0
	}
	#layout-navigation li.nav-item.last {
		margin-bottom: 2rem
	}
	#layout-navigation .navbar-toggler + .navbar {
		background: $white;
		margin-top: 15px;
		box-shadow: 0 3px 6px rgba(0,0,0,.1), 0 1px 0 rgba(0,0,0,.1);
		padding: 0 1rem;
	}
}
@media all and (min-width: 1200px) {
	#layout-navigation .navbar-toggler { 
		.navbar-expand-lg .navbar-collapse {
			display: flex!important;
			flex-basis: auto;
		}
	}
}
@media all and (max-width: 1199px) {
	#layout-navigation .navbar-toggler {
	    .navbar-expand-lg .navbar-collapse { 
	        flex-basis: 100%;
	        flex-grow: 1;
	        align-items: center;
	    }

	}
	.collapse:not(.show) {
		display: none !important;
	}
}
@media all and (min-width: 1200px) {
	#layout-navigation .navbar-toggler { 
		display: none;
	}
}
@media all and (max-width: 1199px) {
	#layout-navigation .navbar-toggler { 
		display: block;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: column;
	}
}
@media all and (max-width:576px) {
	#layout-navigation .navbar-toggler { 
		top: 17px;
		right: 20px;
	}
	#layout-navigation .navbar-toggler + .navbar {
		margin-top: 15px;
	} 
	#language-selector.dropdown {
		margin-right: 38px;
	}
	#layout-navigation .navbar-brand {
		flex: inherit !important;
	}
	#language-selector.dropdown .dropdown-toggle {
		padding-left: 0;
	}
}

#language-selector.dropdown .dropdown-toggle {
	padding:  0.375rem 1.5rem 0.375rem 0;
}
 

#layout-navigation .container-fluid .navbar-nav li a {
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-normal;
	&:hover {
		span {
			color: $green;
		}
	}
}

#layout-navigation .navbar-brand {
	flex: 1
}

#layout-navigation .header-logo {
	background-image: url(../Content/img/logo.svg);
	width: 190px;
	height: 54px;
	margin-right: 10px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@include media-breakpoint-down(sm) {
		width: 150px;
		height: 42px;
	}
}

#layout-navigation .header-logo-wrapper {
	align-items: center;
}

#layout-navigation .navbar-toggler {
	border: none;
	background: 0 0!important;
	padding: .7rem 0
}

#layout-navigation .navbar-toggler:focus {
	outline: 0;
	background: 0 0!important
}

#layout-navigation .navbar-toggler .icon-bar {
	background-color: $black;
	transform: rotate(0) translate(0, 0); 
	transition: ease all .2s;
}

#layout-navigation .navbar-toggler .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	-webkit-border-radius: 1px;
	border-radius: 1px
}

#layout-navigation .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px
}

#layout-navigation .icon-bar:nth-child(2) {
	width: 16px; 
	transition: ease all .2s
}

#layout-navigation .navbar-toggler:hover>.icon-bar:nth-child(2) {
	width: 22px; 
	transition: ease all .2s
}

#layout-navigation .navbar-toggler:active>.icon-bar:nth-child(2) {
	width: 22px; 
	transition: ease all .2s
}

#layout-navigation .navbar-toggler:not(.collapsed) .icon-bar:nth-child(1) {
 
	transform: rotate(45deg) translate(5px, 4px); 
	transition: ease all .2s
}

#layout-navigation .navbar-toggler:not(.collapsed) .icon-bar:nth-child(2) {
	opacity: 0; 
	transition: ease all .2s
}

#layout-navigation .navbar-toggler:not(.collapsed) .icon-bar:nth-child(3) {
	 transform: rotate(-45deg) translate(4px, -4px); 
	transition: ease all .2s
}

#layout-navigation li::before {
	display: none
}

#layout-navigation .logo-copyright {
 
	@include media-breakpoint-down(sm) { 
		display: none !important;
     }
}
#layout-navigation .heading-navigation-brand {

	display: flex;
	align-items: center;
}

@media (max-width:1199px) {
	#layout-navigation div.menu-phone-contacts {
		display: none!important
	}
	#layout-navigation .heading-navigation-brand {
		 
		order: 0
	}
	#layout-navigation .widget-culture-picker-widget {
		 
		order: 1;
		width: 57px;
	}
	#layout-navigation .widget-menu-widget {
		 
		order: 2;
		// width: 100%
	}
}

@media (min-width:1199px) {
	.navbar-brand {
		white-space: initial
	}
}

@media (max-width:1199px) {
	#layout-navigation .container-fluid .navbar-nav li a {
		text-align: right
	}
}


#layout-navigation .menu-phone-contacts {
	font-size: $font-size-lg
}

@media all and (max-width:576px) {
    .logo-copyright {
        display: none !important
    }

    .landing-main-heading-button {
        white-space: normal
    }

    .landing-heading-copyright {
		.landing-main-heading  {
			font-size: 35px;
		}
	}

    #layout-navigation .navbar.zone-navigation {
        align-items: center
    }
}

.sticky.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    width: 100%
}

@media all and (max-width:350px) {
    .widget-SignInButton {
        .mx-4 {
            margin: 0 !important;
            margin-right: 2rem !important;

        }
    }
} 
