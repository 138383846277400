.cookie-bar {
	z-index: $zindex-fixed;
	position: fixed;
	width: 100%;
	color: $white;
	font-family: $font-family-sans-serif;
	bottom: 0;
}

.cookie-bar__inner {
	background-color: $footer-background-color;
	color: $white;
	font-family: $font-family-sans-serif;
	font-size: 1rem;
	padding: 1rem .5rem;
	text-align: center
}

.cookie-bar__message {
	color: $white;
	font-family: $font-family-sans-serif
}

@media (min-width:62rem) {
	.cookie-bar__inner {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center
	}
}

.cookie-bar__buttons {
	position: relative;
	z-index: $zindex-fixed+1;
	display: block;
	margin-top: .375rem
}

.cookie-bar__close {
	color: $primary;
	cursor: pointer;
	padding: 1rem 5rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	-webkit-transform: rotate(-90deg) scale(1);
	-ms-transform: rotate(-90deg) scale(1);
	transform: rotate(-90deg) scale(1)
}

.cookie-bar__close:hover {
	-webkit-transform: rotate(90deg) scale(1.2);
	-ms-transform: rotate(90deg) scale(1.2);
	transform: rotate(90deg) scale(1.2)
}

@media (min-width:62rem) {
	.cookie-bar__buttons {
		display: inline
	}
}

.cookie-bar__btn {
	font-family: $font-family-sans-serif;
	color: $white;
	background-color: transparent;
	border: 1px solid $secondary;
	margin: 0 .875rem;
	padding: .5rem 2rem;
	-webkit-transition: background-color .3s;
	-o-transition: background-color .3s;
	transition: background-color .3s;
	cursor: pointer
}

@media (max-width:36rem) {
	.cookie-bar__btn {
		display: block;
		margin: 0 auto .25rem
	}
}

.cookie-bar__btn:hover {
	background-color: $secondary;
	color: $white
}

.cookie-bar__btn:active {
	background-color: $secondary;
}

.cookie-bar__link {
	margin: 0 .5rem
}

.cookie-bar a {
	color: $white;
	text-decoration: underline
}

.cookie-bar a:hover {
	text-decoration: none
}

.cookie-bar-privacy-popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .5);
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center
}

.cookie-bar-privacy-popup.cookie-bar-privacy-popup--hidden {
	display: none
}

.cookie-bar-privacy-popup__dialog {
	background-color: $white;
	width: 100%;
	max-width: 50rem;
	max-height: 90vh;
	overflow: auto;
	padding: 2rem 1.5rem 1.5rem;
	position: relative
}

.cookie-bar-privacy-popup__dialog__close {
	position: absolute;
	top: 0;
	right: 0;
	background-color: $black;
	color: $white;
	display: block;
	width: 2rem;
	height: 2rem;
	border: none;
	cursor: pointer
}

.cookie-bar-privacy-popup__dialog__close:after,
.cookie-bar-privacy-popup__dialog__close:before {
	content: '';
	display: block;
	width: 1rem;
	height: .25rem;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 25%;
	-webkit-transform-origin: center center;
	-ms-transform-origin: center center;
	transform-origin: center center;
	margin-top: -.125rem
}

.cookie-bar-privacy-popup__dialog__close:before {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg)
}

.cookie-bar-privacy-popup__dialog__close:after {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.cookie-bar-privacy-popup__dialog p:last-child {
	margin-bottom: 0
}

.cookie-bar--bottom,
.cookie-bar--bottom-left,
.cookie-bar--bottom-right {
	z-index: 100;
	position: fixed
}

.cookie-bar--bottom {
	right: 0;
	bottom: 0;
	left: 0
}

.cookie-bar--bottom-left {
	left: .9375rem
}

.cookie-bar--bottom-right {
	right: .9375rem
}

.cookie-bar--bottom-left,
.cookie-bar--bottom-right {
	bottom: .9375rem
}

@media (min-width:48rem) {
	.cookie-bar--bottom-left,
	.cookie-bar--bottom-right {
		width: 22.5rem
	}
}

@media (min-width:48rem) {
	.cookie-bar--bottom-left .cookie-bar__buttons,
	.cookie-bar--bottom-left .cookie-bar__inner,
	.cookie-bar--bottom-right .cookie-bar__buttons,
	.cookie-bar--bottom-right .cookie-bar__inner {
		display: block
	}
}

@media (min-width:48rem) {
	.cookie-bar--bottom-left .cookie-bar__buttons,
	.cookie-bar--bottom-right .cookie-bar__buttons {
		margin-top: .375rem
	}
}

.cookie-bar--bottom-left .cookie-bar__inner,
.cookie-bar--bottom-right .cookie-bar__inner {
	line-height: 1.3
}