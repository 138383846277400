// General Orchard Styling / rewriting
body,
html {
	height: 100%
}

#layout-wrapper { 
	display: flex; 
	flex-direction: column;
	height: 100%
}

#layout-main {
	padding-top: 9rem;
	flex: 1
}

#layout-after-main .container-fluid {
	padding: 0!important;
	overflow: hidden
}

#layout-main header {
    h1 {
        display: none;
    }
}

#layout-content .zone.zone-content .page.content-item header {
    display: none
}

.section-wrapper{
    padding: 100px 0 100px 0;

	@include media-breakpoint-down(xl) {
		padding: 80px 0 80px 0;
	}

	@include media-breakpoint-down(md) {
		padding: 60px 0 60px 0;
	}
	@include media-breakpoint-down(sm) {
		padding: 50px 0 50px 0;
	}
}

