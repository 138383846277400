.page-error {
    text-align: center;
    margin-bottom: 80px;

    .page-error-content {
        position: relative;
        z-index: 1;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .page-error-title-big {
        line-height: 1;
        font-size: 150px;
        font-weight: $font-weight-bold;
        font-family: $font-family-sans-serif;
    }

    .page-error-title {
        font-size: 3.375rem;
        line-height: 1.2;
        margin-bottom: 1rem;
        font-weight: $font-weight-bold;
        font-family: $font-family-sans-serif;
    }

    .page-error-info {
        font-size: 1rem;
        font-weight: $font-weight-bold;
        font-family: $font-family-sans-serif;
    }
}