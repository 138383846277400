.captcha-wrapper {
  position: relative;
}

.captcha-wrapper:after {
  position: absolute;
  top: 86px;
  left: 12px;
  width: calc(100% - 25px);
  height: 7px;
  background: $gray-100;
  border-left: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  content: '';

}

@media screen and (max-width: 362px){
  .captcha-wrapper:after,
  .captcha-wrapper:before {
    width: 110%!important;
  }

}

.captcha-wrapper:before {
  position: absolute;
  top: 13px;
  left: 12px;
  width: calc(100% - 25px);
  height: 145px;
  background: $gray-100;
  content: '';
  border-radius: $recaptcha-border-radius;
  border: 1px solid $gray-200;

}

.captcha-wrapper .g-recaptcha {
  position: relative;
  margin-left: -0.3em;
}

.captcha-wrapper .g-recaptcha:after {
  position: absolute;
  top: 0px;
  left: 300px;
  content: '';
  width: 13px;
  height: 78px;
  background: $gray-100;
  z-index: 2;
  border-top: 1px solid $gray-200;
}
.captcha-wrapper .form-field-element {
  margin-left: 0.3em;
}

.field-validation-error {
  position: relative;
  z-index: 2;
}